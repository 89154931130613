<template>
  <div>
    <v-col class="text-center py-0" v-if="loadingBackground">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-col>
    <div v-if="!loadingBackground">
      <div class="img-header">
        <v-img contain type="image" max-height="270" :src="imageUrl"></v-img>
        <h1 v-if="conference_list.length > 0">
          {{ conference_list[conference_list.length - 1].title }}
        </h1>
      </div>
      <v-spacer></v-spacer>
      <div class="header-conf-rel">
        <h3 v-if="disable_conference">
          Não há conferência vigente no momento!
        </h3>

        <div class="text-center">
          <v-btn
            v-if="!disable_conference"
            @click="editConference()"
            class="cards"
          >
            <v-icon size="32" class="icon" color="icon">mdi-pencil</v-icon>
            <span
              v-if="
                $store.state.profile_id != 1 && $store.state.profile_id != 2
              "
            >
              Edição do Responsável
            </span>
            <span v-else> Editar Conferência </span>
          </v-btn>
          <v-dialog v-model="dialogEdit" width="800">
            <AlertError2
              class="mb-5"
              v-model="displayError2"
              :resource="displayError2"
            />
            <v-card>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">
                    Cadastro
                  </v-stepper-step>
                  <v-divider> </v-divider>
                  <v-stepper-step :complete="e1 > 2" step="2">
                    Observação
                  </v-stepper-step>
                  <v-divider> </v-divider>
                  <v-stepper-step :complete="e1 > 3" step="3">
                    Endereço
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card class="mb-12 px-5 py-5" height="480px">
                      <v-form
                        ref="form"
                        :disabled="disabled_continue"
                        lazy-validation
                        class="tab-1-dialog"
                      >
                        <v-text-field
                          label="Nome"
                          :rules="nameRules"
                          :counter="60"
                          v-model="name"
                          id="name"
                          placeholder="Nome*"
                          outlined
                          dense
                          class="input-ajust grid-col-ajust"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="Nome Social"
                          v-model="socialName"
                          :counter="60"
                          id="social"
                          placeholder="Nome Social"
                          outlined
                          dense
                          class="input-ajust grid-col-segund"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="CPF"
                          masked="false"
                          v-mask.raw="'###.###.###-##'"
                          :rules="cpfRules"
                          v-model="cpfCommission"
                          id="CPF"
                          placeholder="CPF*"
                          outlined
                          dense
                          class="input-ajust crash"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="Data de nascimento"
                          v-model="birthDate"
                          type="date"
                          id="date"
                          placeholder="Data de Nascimento"
                          outlined
                          dense
                          class="input-ajust columns-5-3"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="E-mail*"
                          :counter="70"
                          :rules="emailRules"
                          v-model="email"
                          id="email"
                          type="email"
                          placeholder="E-mail"
                          outlined
                          dense
                          class="input-ajust crash"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="Telefone"
                          :rules="mandatory"
                          v-mask="['(##) ####-#####']"
                          v-model="tell"
                          id="date"
                          placeholder="Telefone"
                          outlined
                          dense
                          class="input-ajust columns-5-3"
                          required
                        ></v-text-field>
                        <v-select
                          :items="getAllGender"
                          item-text="name"
                          item-value="id"
                          id="sex"
                          :rules="mandatory"
                          v-model="genre"
                          label="Gênero"
                          required
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          :items="getAllBreed"
                          :rules="mandatory"
                          item-text="name"
                          item-value="id"
                          id="breed"
                          v-model="breed"
                          label="Raça/Cor"
                          required
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          style="max-width: 200px;"
                          :items="getAllEntity"
                          item-text="name"
                          item-value="id"
                          id="Ôrgão/Entidade"
                          v-model="issuingAgency"
                          label="Ôrgão/Entidade"
                          required
                          dense
                          outlined
                        ></v-select>

                        <v-text-field
                          label="RG"
                          v-model="rg"
                          v-mask="['##.###.###-##']"
                          placeholder="RG"
                          outlined
                          dense
                          required
                        ></v-text-field>

                        <v-text-field
                          label="Orgão/Emissor"
                          :counter="4"
                          v-model="issuer"
                          max="4"
                          placeholder="Ex: SSP"
                          outlined
                          dense
                          class="input-ajust r"
                          required
                        ></v-text-field>
                      </v-form>
                    </v-card>
                    <div class="bnt-bottom-conf">
                      <v-btn
                        color="primary"
                        :disabled="disabled_continue"
                        @click="nexTab()"
                      >
                        Continuar
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card class="mb-12 px-5 py-5" height="300px">
                      <v-form ref="form2" lazy-validation class="tab-2-dialog">
                        <v-textarea
                          filled
                          no-resize
                          v-model="observation"
                          solo
                          class="textareamain"
                          label="Observação"
                        ></v-textarea>
                      </v-form>
                    </v-card>
                    <v-snackbar v-model="snackbar">
                      {{ message2 }}
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="green"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Fechar
                        </v-btn>
                      </template>
                    </v-snackbar>
                    <div class="bnt-bottom-conf">
                      <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                      <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                      <v-btn color="primary" @click="nexTabTwo()">
                        continuar
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card class="mb-12 px-5 py-5" height="300px">
                      <v-form ref="form3" lazy-validation class="tab-1-dialog">
                        <v-text-field
                          label="Logradouro"
                          type="text"
                          :rules="nameLogradouro"
                          :counter="60"
                          placeholder="Logradouro"
                          v-model="publicPlace"
                          outlined
                          dense
                          class="input-ajust grid-col-ajust"
                          required
                        ></v-text-field>

                        <v-text-field
                          label="CEP"
                          id="cep"
                          :rules="mandatory"
                          placeholder="CEP"
                          v-model="cep"
                          v-mask="'#####-###'"
                          outlined
                          dense
                          class="input-ajust grid-col-segund"
                          required
                        ></v-text-field>

                        <v-text-field
                          label="BAIRRO"
                          v-model="district"
                          placeholder="BAIRRO"
                          :rules="namesTrick"
                          :counter="40"
                          outlined
                          dense
                          class="input-ajust grid-col-ajust"
                          required
                        ></v-text-field>

                        <v-select
                          :items="getAllCounty"
                          item-text="name"
                          item-value="id"
                          v-model="municipaly"
                          :rules="mandatory"
                          class="input-ajust grid-col-segund"
                          label="Municipio"
                          required
                          dense
                          outlined
                        ></v-select>
                        <v-text-field
                          label="NÚMERO"
                          v-model="numberStreet"
                          type="number"
                          id="numberStreet"
                          :counter="3"
                          min="0"
                          placeholder="Número Residência"
                          :rules="numberResidencie"
                          outlined
                          dense
                          class="input-ajust grid-col-ajust"
                          required
                        ></v-text-field>
                      </v-form>
                    </v-card>
                    <v-snackbar v-model="snackbar">
                      {{ message2 }}
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="green"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Fechar
                        </v-btn>
                      </template>
                    </v-snackbar>
                    <div class="bnt-bottom-conf">
                      <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                      <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                      <v-btn color="primary" @click="submitFormEdit()">
                        Finalizar
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-dialog>
        </div>

        <v-btn class="cards" v-show="disabled_default" @click="nexLauch">
          <v-icon size="32" color="icon">mdi-calendar-check-outline</v-icon>
          Lançamentos de Conferências
        </v-btn>
        <v-btn class="cards" @click="report()">
          <v-icon size="32" color="icon">mdi-format-list-bulleted-type</v-icon>
          Relatórios
        </v-btn>
      </div>

      <v-row>
        <v-card class="table-dashboard">
          <v-card-title>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="notificationSearch"
                  append-icon="mdi-magnify"
                  label="Pesquisar Resumo"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="authorSearch"
                  append-icon="mdi-magnify"
                  label="Pesquisar Autor"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  single-line
                  hide-details
                  clearable
                  v-model="profileSearch"
                  :items="profile_list"
                  item-text="name"
                  item-value="id"
                  label="Perfis"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  v-model="initialDate"
                  label="Data Inicio"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  v-model="finalDate"
                  label="Data Final"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                md="12"
                style="display: flex; justify-content: end"
              >
                <v-btn
                  color="primary"
                  dark
                  class="mt-3 ml-2"
                  v-show="municipio"
                  @click="dialog_notification = true"
                >
                  CRIAR
                </v-btn>
                <v-btn
                  color="success"
                  dark
                  class="mt-3 ml-2"
                  @click="initialize()"
                >
                  Pesquisar
                </v-btn>
                <v-btn
                  color="red"
                  dark
                  class="mt-3 ml-2"
                  @click="clearSearchNotification()"
                >
                  Limpar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :footer-props="{
              'items-per-page-text': 'Registros por Página',
            }"
            :page="page"
            :pageCount="numberOfPages"
            :options.sync="options"
            :sort-by.sync="sortBy"
            :server-items-length="totalRegister"
            :loading="loading"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="ml-5"
                  >Últimas atualizações</v-toolbar-title
                >
                <v-divider class="title-table mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.notification_date`]="{ item }">
              <v-chip class="status-conference" :color="getColor(item)" dark>
              </v-chip>
              <span>{{
                new Date(item.notification_date).toLocaleString()
              }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-row>

      <v-dialog v-model="dialog_notification" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-card>
                <v-form ref="form" lazy-validation>
                  <v-textarea
                    v-model="resume"
                    :rules="resumeRules"
                    clearable
                    no-resize
                    label="Resumo"
                  ></v-textarea>
                </v-form>
              </v-card>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_notification = false"
            >
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="save()">
              Enviar Notificação
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogLaunch" persistent max-width="880">
        <AlertError2
          class="mb-5"
          v-model="displayError2"
          :resource="displayError2"
        />

        <v-card>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Cadastro
              </v-stepper-step>
              <v-divider> </v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">
                Observação
              </v-stepper-step>
              <v-divider> </v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">
                Endereço
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row
                  v-if="
                    this.$store.state.profile_id == 1 ||
                    this.$store.state.profile_id == 2 ||
                    this.$store.state.profile_id == 3 ||
                    this.$store.state.profile_id == 4 ||
                    this.$store.state.profile_id == 6 ||
                    this.$store.state.profile_id == 8
                  "
                >
                  <v-col cols="6" class="px-5 py-5">
                    <v-text-field
                      label="Pesquisar responsável por CPF"
                      v-model="responsibleCPF"
                      v-mask.raw="'###.###.###-##'"
                      placeholder="Pesquisar responsável por CPF"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      class="mt-2"
                      color="primary"
                      @click="searchResponsibleByCpf()"
                    >
                      Pesquisar
                    </v-btn>
                    <v-btn
                      class="mt-2 ml-2"
                      color="#C62828"
                      style="color: white"
                      @click="resetForm()"
                    >
                      Limpar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card class="mb-12 px-5 py-5" height="480px">
                  <v-form ref="form" lazy-validation class="tab-1-dialog">
                    <v-text-field
                      label="Nome"
                      :rules="nameRules"
                      :counter="30"
                      v-model="name"
                      id="name"
                      placeholder="Nome*"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Nome Social"
                      v-model="socialName"
                      :counter="30"
                      id="social"
                      placeholder="Nome Social"
                      outlined
                      dense
                      class="input-ajust grid-col-segund"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="CPF"
                      masked="false"
                      v-mask.raw="'###.###.###-##'"
                      :rules="cpfRules"
                      v-model="cpfCommission"
                      id="CPF"
                      placeholder="CPF*"
                      outlined
                      dense
                      class="input-ajust crash"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Data de nascimento"
                      v-model="birthDate"
                      type="date"
                      id="date"
                      placeholder="Data de Nascimento"
                      outlined
                      dense
                      class="input-ajust columns-5-3"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="E-mail*"
                      :counter="70"
                      :rules="emailRules"
                      v-model="email"
                      id="email"
                      type="email"
                      placeholder="E-mail"
                      outlined
                      dense
                      class="input-ajust crash"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Telefone"
                      :rules="mandatory"
                      v-mask="['(##) ####-#####']"
                      v-model="tell"
                      id="date"
                      placeholder="Telefone"
                      outlined
                      dense
                      class="input-ajust columns-5-3"
                      required
                    ></v-text-field>
                    <v-select
                      :items="getAllGender"
                      item-text="name"
                      item-value="id"
                      id="sex"
                      :rules="mandatory"
                      v-model="genre"
                      label="Gênero"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-select
                      :items="getAllBreed"
                      :rules="mandatory"
                      item-text="name"
                      item-value="id"
                      id="breed"
                      v-model="breed"
                      label="Raça/Cor"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-select
                      style="max-width: 200px"
                      :items="getAllEntity"
                      item-text="name"
                      item-value="id"
                      id="Ôrgão/Entidade"
                      v-model="issuingAgency"
                      label="Ôrgão/Entidade"
                      required
                      dense
                      outlined
                    ></v-select>

                    <v-text-field
                      label="RG"
                      id="tellResi"
                      v-model="rg"
                      v-mask="['##.###.###-##']"
                      placeholder="RG"
                      outlined
                      dense
                      class="input-ajust r"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="Orgão/Emissor"
                      :counter="4"
                      v-model="issuer"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </v-form>
                </v-card>
                <div class="bnt-bottom-conf">
                  <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12 px-5 py-5" height="300px">
                  <v-form ref="form2" lazy-validation class="tab-2-dialog">
                    <v-textarea
                      filled
                      no-resize
                      v-model="observation"
                      solo
                      class="textareamain"
                      label="Observação"
                    ></v-textarea>
                  </v-form>
                </v-card>
                <v-snackbar v-model="snackbar">
                  {{ message2 }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="green"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Fechar
                    </v-btn>
                  </template>
                </v-snackbar>
                <div class="bnt-bottom-conf">
                  <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                  <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                  <v-btn color="primary" @click="nexTabTwo()">
                    continuar
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card class="mb-12 px-5 py-5" height="300px">
                  <v-form ref="form3" lazy-validation class="tab-1-dialog">
                    <v-text-field
                      label="Logradouro"
                      type="text"
                      :rules="nameLogradouro"
                      :counter="60"
                      placeholder="Logradouro"
                      v-model="publicPlace"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="CEP"
                      id="cep"
                      :rules="mandatory"
                      placeholder="CEP"
                      v-model="cep"
                      v-mask="'#####-###'"
                      outlined
                      dense
                      class="input-ajust grid-col-segund"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="BAIRRO"
                      v-model="district"
                      placeholder="BAIRRO"
                      :rules="namesTrick"
                      :counter="40"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>

                    <v-select
                      :items="getAllCounty"
                      item-text="name"
                      item-value="id"
                      v-model="municipaly"
                      :rules="mandatory"
                      class="input-ajust grid-col-segund"
                      label="Municipio"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-text-field
                      label="NÚMERO"
                      v-model="numberStreet"
                      type="number"
                      id="numberStreet"
                      :counter="3"
                      min="0"
                      placeholder="Número Residência"
                      :rules="numberResidencie"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>
                  </v-form>
                </v-card>
                <v-snackbar v-model="snackbar">
                  {{ message2 }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="green"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Fechar
                    </v-btn>
                  </template>
                </v-snackbar>
                <div class="bnt-bottom-conf">
                  <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                  <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                  <v-btn color="primary" @click="submitForm()">
                    {{ buttom_name }}
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
      <!-- <v-dialog class="" v-model="loadingBackground" persistent width="auto"> -->
      <!-- <div class="loading-background"> -->
      <!-- <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular> -->
      <!-- </div> -->
      <!-- </v-dialog> -->
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data: () => ({
    resume: "",
    disabled_continue: true,
    municipio: false,
    hidden: false,
    colorUser: null,
    conference_list: [],
    disabled_button: true,
    dialog: false,
    loadingBackground: true,
    dialogLaunch: false,
    disable_conference: false,
    e1: 1,
    dialogEdit: false,
    snackbar: false,
    id_responsible: null,
    notificationSearch: "",
    authorSearch: "",
    profileSearch: "",
    initialDate: "",
    finalDate: "",
    profile_list: [],
    //Responsáveis
    breastfeeding: false,
    escortActive: false,
    message2: null,

    displayError: {
      display: false,
      message: [],
    },

    displayError2: {
      display2: false,
      message2: [],
    },
    page: 1,
    totalRegister: 0,
    numberOfPages: 0,
    loading: true,
    sortBy: "date",

    // Cadastro
    name: null,
    socialName: null,
    modality: null,
    ownership: null,
    ownerships: [],
    modalitys: [],
    cpfCommission: "",
    segment: null,
    segments: [],
    birthDate: null,
    genre: null,
    breed: null,
    issuingAgency: null,
    rg: null,
    checkedDeficiency: false,
    selectDeficiency: null,
    tell: "",
    issuer: "",
    email: null,
    nameEscort: null,
    rgEscort: null,
    cpfEscort: null,
    nameSocialEscort: null,
    genderEscort: null,
    genreEscort: null,
    observation: null,
    responsibleCPF: "",
    //IDS
    id_phone: null,
    id_address: null,
    id_email: null,
    id_rg: null,
    id_cpf: null,

    // Endereço
    publicPlace: null,
    cep: null,
    numberStreet: null,
    municipaly: null,
    district: null,

    getAllGender: [],
    getAllBreed: [],
    getAllCounty: [],
    getAllDeficiency: [],
    getAllEntity: [],
    dialog_notification: false,
    //Estrutura
    formData: {
      people: {
        name: null,
        gender_id: null,
        modality_id: null,
        birth_date: null,
        social_name: null,
        agency_id: null,
        breed_id: null,
        conference_id: null, //Id da conferência vinculada
      },
      address: {
        cep: null,
        county_id: null,
        public_place: null,
        address_number: null,
        district: null,
      },

      phone: [],
      documents: {
        RG: {
          document_number: null,
          issuing_body: null,
        },
        CPF: {
          document_number: null,
        },
      },
      email: {
        name: null,
      },
    },

    // Regras de validação
    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    resumeRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 150) || "Você excedeu o tamanho máximo.",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
    ],

    numberResidencie: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],

    people_conference_id: null,
    //Fim de responsáveis
    requestType: "salvar",
    responsible_id: "",
    buttom_name: "SALVAR",
    options: {},
    firstname: "",
    oneName: "",
    dialogDelete: false,
    search: "",
    profile: null,
    profileAll: null,
    imageUrl: null,
    per_page: 10,
    disabled_default: false,
    desabled_button_button: false,
    headers: [
      { text: "DATA", value: "notification_date", sortable: false },
      { text: "Autor", value: "users_name", sortable: false },
      { text: "Resumo", value: "notification_resume", sortable: false },
      { text: "Perfil", value: "profile_name", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Enviar Notificação"
        : "Editar Notificação";
    },
  },

  components: {
    AlertError2,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.verifyIfThereIsAnyConference();
    this.checkLogin();
    this.initialize();

    axios.get(`/profiles`).then((response) => {
      this.profile_list = response.data.data.filter(
        (x) => x.id != 7 && x.id != 5
      );
    });
  },

  methods: {
    clearSearchNotification() {
      this.notificationSearch = "";
      this.authorSearch = "";
      this.profileSearch = "";
      this.initialDate = "";
      this.finalDate = "";
      this.initialize();
    },
    searchResponsibleByCpf() {
      const newCPF = this.responsibleCPF.replace(/[^a-zA-Z0-9]/g, "");
      axios.post("people/get-delegate-by-cpf", { cpf: newCPF }).then((res) => {
        let item = res.data;
        if (!item) {
          return;
        }
        let cpf;
        let rg;
        cpf = this.getRgAndCpf(item.documents).cpf[0];
        if (item.documents.length > 1) {
          rg = this.getRgAndCpf(item.documents).rg[0];
        }

        this.id_cpf = cpf.id;
        if (rg) {
          this.id_rg = rg.id;
          this.rg = rg.document_number;
          this.issuer = rg.issuing_body;
        }

        this.cpfCommission = cpf.document_number;

        this.name = item.name;
        this.requestType = "editar";
        this.responsible_id = item.id;
        this.buttom_name = "ATUALIZAR";
        //ID de registro

        this.id_email = item.email[0].id;
        this.id_phone = item.phone[0].id;

        this.id_delegate = item.id;
        this.id_address = item.address[0].id;

        this.county_id = item.county_id;
        this.institute_id = item.institute_id;
        this.regional_nucleus_health_id = item.regional_nucleus_health_id;

        //Registro
        this.sexuality = Number(item.sexuality_id);
        this.socialName = item.social_name;

        this.birthDate =
          item.birth_date == null ? "" : item.birth_date.slice(0, 10);
        this.genre = Number(item.gender_id);
        this.breed = Number(item.breed_id);
        this.issuingAgency = Number(item.agency_id);
        this.tell = item.phone[0].phone_number + item.phone[0].ddd;
        this.email = item.email[0].name;

        // Endereço
        this.publicPlace = item.address[0].public_place;
        this.cep = item.address[0].cep;
        this.numberStreet = item.address[0].address_number;
        this.municipaly = Number(item.address[0].county_id);
        this.district = item.address[0].district;
      });
    },

    getRgAndCpf(documents) {
      let rg = null;
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");

      if (documents.length > 1) {
        rg = documents.filter((x) => x.document_type_id == "2");
      }
      return { rg: rg, cpf: cpf };
    },

    filterArchiveByType(archives, archive_type_id) {
      return archives.filter(
        (archive) => archive.archive_type_id == archive_type_id
      );
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$store.state.authCode = "";
    },
    verifyIfThereIsAnyConference() {
      axios.get(`/conference`).then((response) => {
        this.conference_list = response.data;

        this.$store.state.conference_id =
          response.data[this.conference_list.length - 1].id;

        if (this.conference_list.length == 0) {
          this.disable_conference = true;
        }

        axios
          .post(
            "/archives/download",
            {
              archive_id: this.filterArchiveByType(
                response.data[this.conference_list.length - 1].archive_cover,
                2
              )[0]["id"],
            },
            { responseType: "blob" }
          )
          .then((response) => {
            this.imageUrl = URL.createObjectURL(
              new Blob([response.data], { type: "image/jpeg" })
            );
          });
      });
    },

    resetForm() {
      this.requestType = "salvar";
      this.buttom_name = "SALVAR";
      this.$refs.form3.reset();
      this.$refs.form2.reset();
      this.$refs.form.reset();
      this.delegateCPF = "";
      this.formData.people = {};
      this.responsibleCPF = "";
      this.birthDate = "";
      this.tell = "";
    },

    editConference() {
      if (
        Number(this.$store.state.profile_id) == 1 ||
        Number(this.$store.state.profile_id) == 2
      ) {
        this.$router.push({
          path: "/dashboard/conference/edit",
          query: { id: this.conference_list[0].id },
        });
      }
      if (Number(this.$store.state.profile_id) == 3) {
        this.dialogEdit = true;
        this.getFunction();
        axios
          .get(
            `people-conference/${Number(
              this.$store.state.county_id
            )}/responsible-by-county-id`,
            { query: { id: Number(this.conference_list[0].id) } }
          )
          .then((res) => {
            this.fillFormResponsible(res);
          });
      }

      if (Number(this.$store.state.profile_id) == 4) {
        this.dialogEdit = true;
        this.getFunction();
        axios
          .get(
            `people-conference/${Number(
              this.$store.state.institute_id
            )}/responsible-by-institute-id`,
            { query: { id: Number(this.conference_list[0].id) } }
          )
          .then((res) => {
            this.fillFormResponsible(res);
          });
      }

      if (Number(this.$store.state.profile_id) == 6) {
        this.dialogEdit = true;
        this.getFunction();
        axios
          .get(
            `people-conference/${Number(
              this.$store.state.regional_nucleus_health_id
            )}/responsible-by-regional-nucleus-health-id`,
            { query: { id: Number(this.conference_list[0].id) } }
          )
          .then((res) => {
            this.fillFormResponsible(res);
          });
      }

      if (Number(this.$store.state.profile_id) == 8) {
        this.dialogEdit = true;
        this.getFunction();
        axios
          .get(
            `people-conference/${Number(
              this.$store.state.state_id
            )}/responsible-by-state-id`,
            { query: { id: Number(this.conference_list[0].id) } }
          )
          .then((res) => {
            this.fillFormResponsible(res);
          });
      }
    },

    fillFormResponsible(res) {
      this.disabled_continue = false;
      //IDS

      this.id_responsible = res.data.responsible.id;
      this.id_phone = res.data.responsible.phone[0].id;
      this.id_email = res.data.responsible.email[0].id;
      this.id_address = res.data.responsible.address[0].id;

      this.name = res.data.responsible.name;
      this.socialName = res.data.responsible.social_name;
      this.birthDate =
        res.data.responsible.birth_date == null
          ? ""
          : res.data.responsible.birth_date.slice(0, 10);

      this.email = res.data.responsible.email[0].name;
      this.tell =
        res.data.responsible.phone[0].ddd +
        res.data.responsible.phone[0].phone_number;
      this.genre = Number(res.data.responsible.gender_id);
      this.breed = Number(res.data.responsible.breed_id);
      this.issuingAgency = Number(res.data.responsible.agency_id);

      if (res.data.responsible.rg) {
        this.id_rg = res.data.responsible.rg[0].id;
        this.rg = res.data.responsible.rg[0].document_number;
        this.issuer = res.data.responsible.rg[0].issuing_body;
      }

      this.id_cpf = res.data.responsible.cpf[0].id;
      this.cpf = res.data.responsible.cpf[0].document_number;
      this.cpfCommission = res.data.responsible.cpf[0].document_number;

      this.observation = res.data.responsible.observation;
      this.cep = res.data.responsible.address[0].cep;
      this.publicPlace = res.data.responsible.address[0].public_place;
      this.numberStreet = res.data.responsible.address[0].address_number;
      this.district = res.data.responsible.address[0].district;
      this.municipaly = Number(res.data.responsible.address[0].county_id);
      this.people_conference_id = res.data.id;
    },
    getFunction() {
      axios.get("segment").then((res) => {
        this.segments = res.data;
      });
      axios.get("modality").then((res) => {
        this.modalitys = res.data;
      });
      axios.get("ownership").then((res) => {
        this.ownerships = res.data;
      });

      axios.get(`/gender`).then((res) => {
        this.getAllGender = res.data;
      });

      axios.get(`/breed`).then((res) => {
        this.getAllBreed = res.data;
      });

      axios.get(`/county`).then((res) => {
        this.getAllCounty = res.data;
      });

      axios.get(`/deficiency`).then((res) => {
        this.getAllDeficiency = res.data;
      });

      axios.get(`/agency`).then((res) => {
        this.getAllEntity = res.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;
      let phone_id = this.id_phone;
      function removeFirstTwoDigits(phoneNumber) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        if (phone_id == null) {
          return [{ ddd: ddd, phone_number: phoneNumber }];
        }

        return [{ id: phone_id, ddd: ddd, phone_number: phoneNumber }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      let newRG = null;
      if (this.rg != null) {
        newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      }

      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      // First stap
      this.formData.people.requestType = this.requestType;
      this.formData.people.people_id = this.responsible_id;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.email.id = this.id_email;
      this.formData.address.id = this.id_address;

      if (this.requestType == "editar") {
        this.formData.people.county_id = this.county_id;
        this.formData.people.institute_id = this.institute_id;
        this.formData.people.regional_nucleus_health_id =
          this.regional_nucleus_health_id;
      }

      this.formData.people.conference_id =
        this.conference_list[this.conference_list.length - 1].id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.phone = removeFirstTwoDigits(newPhone);

      this.formData.people.gender_id = this.genre;
      this.formData.people.ownership_id = this.ownership;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;
      // End First stap

      this.formData.people.observation = this.observation;
      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      // End Endereço
      axios
        .post("people/responsible", this.formData)
        .then((res) => {
          // this.snackbar = true;
          // this.message2 = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          this.$swal({
            icon: "success",
            title: "Cadastro realizado com sucesso!",
            text: res.data.message,
          });
          // setTimeout(() => {
          this.e1 = 1;
          this.dialogLaunch = false;
          // }, 2000);
        })
        .catch((err) => {
          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar responsável",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar responsável",
                text: err.data.details,
              });
            }
          }
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledSubmit = false;
          }, 5000);
        });
    },

    submitFormEdit() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;

      function removeFirstTwoDigits(phoneNumber, id) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        return [{ ddd: ddd, phone_number: phoneNumber, id }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      let newRG = null;
      if (this.rg != null) {
        newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      }

      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      this.formData.people.people_conference_id = this.people_conference_id;
      this.formData.people.conference_id =
        this.conference_list[this.conference_list.length - 1].id;
      // First stap
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.email.id = this.id_email;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.phone = removeFirstTwoDigits(newPhone, this.id_phone);

      this.formData.people.gender_id = this.genre;
      this.formData.people.ownership_id = this.ownership;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;
      // End First stap

      this.formData.people.observation = this.observation;
      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      this.formData.address.id = this.id_address;
      // End Endereço

      axios
        .put(`people/${this.id_responsible}/responsible`, this.formData)
        .then((res) => {
          this.snackbar = true;
          this.message2 = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          setTimeout(() => {
            this.e1 = 1;
            this.dialogEdit = false;
          }, 2000);
        })
        .catch(() => {
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledSubmit = false;
          }, 5000);
        });
    },

    initialize() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      data.conference_id = this.$store.state.conference_id;
      axios
        .get(`/notification`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            conference_id: this.$store.state.conference_id,
            resume: this.notificationSearch,
            author_name: this.authorSearch,
            profile_id: this.profileSearch,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
          },
        })
        .then((response) => {
          this.loading = false;
          this.desserts = response.data.data;
          this.totalRegister = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    // resetForm() {
    //   this.$refs.form3.reset();
    //   this.$refs.form3.resetValidation();
    // },

    checkLogin() {
      axios.get("auth/me").then((res) => {
        this.profileAll = res.data;
        this.disabled_button = Number(res.data.finished_release_conference);

        this.profile = Number(res.data.profile_id);
        this.municipio =
          this.profile == 1 ||
          this.profile == 2 ||
          this.profile == 21 ||
          this.profile == 41
            ? true
            : false;

        if (
          this.disabled_button &&
          Number(res.data.restrict_release_conference)
        ) {
          if (
            this.profile == 1 ||
            this.profile == 2 ||
            this.profile == 21 ||
            this.profile == 41
          ) {
            this.disabled_default = true;
          } else {
            this.disabled_default = false;
          }
        } else {
          this.disabled_default = true;
        }

        if (this.profile == 1 || this.profile == 2) {
          this.loadingBackground = false;
          return;
        }

        if (Number(this.profileAll.first_login)) {
          this.profile == 4 ? (this.hidden = true) : (this.hidden = false);
          this.getFunction();
          this.loadingBackground = false;
          this.dialogLaunch = true;
        }
        this.loadingBackground = false;
      });
    },

    nexLauch() {
      switch (Number(this.profile)) {
        case 1:
          this.$router.push({
            path: "/dashboard/conference/general",
            query: { id: this.conference_list[0].id },
          });
          break;
        case 2:
          this.$router.push({
            path: "/dashboard/conference/general",
            query: { id: this.conference_list[0].id },
          });
          break;
        case 3:
          this.$router.push({
            path: "/dashboard/conference/launch",
            query: {
              id: this.conference_list[0].id,
              county_id: this.profileAll.county_id,
            },
          });
          break;
        case 4:
          this.$router.push({
            path: "/dashboard/conference/institution",
            query: {
              id: this.conference_list[0].id,
              institute_id: this.profileAll.institute_id,
            },
          });
          break;
        case 6:
          this.$router.push({
            path: "/dashboard/conference/regional-nucleus",
            query: {
              id: this.conference_list[0].id,
              regional_nucleus_health_id:
                this.profileAll.regional_nucleus_health_id,
            },
          });
          break;
        case 8:
          this.$router.push({
            path: "/dashboard/conference/state",
            query: {
              id: this.conference_list[0].id,
              state_id: this.profileAll.state_id,
            },
          });
          break;
        case 21:
          this.$router.push({
            path: "/dashboard/conference/general",
            query: { id: this.conference_list[0].id },
          });
          break;
        case 41:
          this.$router.push({
            path: "/dashboard/conference/general",
            query: { id: this.conference_list[0].id },
          });
          break;
        default:
          alert("Seu lançamento está indisponível!");
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },

    nexTabTwo() {
      if (!this.$refs.form2.validate()) return;
      this.e1 = 3;
    },

    save() {
      let data = new Object();
      data.resume = this.resume;
      data.notification_type_id = 1;
      data.conference_id = this.$store.state.conference_id;

      axios
        .post(`/notification`, data)
        .then((response) => {
          this.loading = false;
          this.desserts = response.data.data;
          this.totalRegister = response.data.total;
          this.numberOfPages = response.data.last_page;
          this.resume = "";
        })
        .then(() => {
          this.initialize();
          this.close();
        });
    },

    report() {
      this.$router.push({
        path: "/dashboard/conference/report",
        query: { id: this.conference_list[0].id },
      });
    },

    getColor(item) {
      switch (true) {
        case Number(item.profile_id) === 1:
          return "#2F3038";
        case Number(item.profile_id) === 2:
          return "#BD372E";
        case Number(item.profile_id) === 3:
          return "#31B7BC";
        case Number(item.profile_id) === 4:
          return "#034991";
        case Number(item.profile_id) === 6:
          return "#EF7D00";
        case Number(item.profile_id) === 8:
          return "#BD372E";
      }
    },
  },

  validate() {
    this.$refs.form.validate();
  },
};
</script>

<style scoped>
.img-header {
  height: auto;
  width: 100%;

  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.status-conference {
  height: 15px !important;
  margin-right: 10px;
}

.cards {
  height: 70px !important;
}

.test {
  height: 20px !important;
  border-radius: 50%;
}

.header-conf-rel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap: 20px;
}

.header-conf-rel a .icon,
.header-conf-rel a p {
  height: auto;
  margin-bottom: 0;
}

.loading-background {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100% !important;
  margin: 0px !important;
}

.card-edit-conference,
.header-conf-rel a {
  width: 400px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 5px;
  outline: none;
  text-decoration: none;
  height: 70px;
  transition: 0.3s;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.card-edit-conference {
  border-radius: 0px !important;
  height: 68px !important;
}

.card-edit-conference,
.header-conf-rel a:nth-child(1) {
  background-color: rgb(39, 164, 202);
}

.header-conf-rel a:nth-child(1):hover {
  background-color: rgb(36, 136, 167);
}

.textareamain {
  height: 100%;
  width: 100%;
}

.header-conf-rel a:nth-child(2) {
  background-color: rgb(25, 192, 100);
}

.header-conf-rel a:nth-child(2):hover {
  background-color: rgb(24, 146, 79);
}

.header-conf-rel a:nth-child(3) {
  background-color: rgb(199, 187, 27);
}

.header-conf-rel a:nth-child(3):hover {
  background-color: rgb(153, 144, 23);
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}

.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.r {
  grid-row: 5;
}

.grid-col-segund {
  grid-column: 3 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.table-center {
  display: flex;
  align-items: center;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px 123px;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.v-chip {
  padding: 0 3px !important;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
